<div class="flex-col rg8">
    <div class="flex-row" *ngIf="transaction.metadata.issued_to">
        <div class="flex-1">
            <div>
                <span class="subtitle1" i18n>Cardholder</span>
            </div>
            <span> {{ transaction.metadata.issued_to }} </span>
        </div>
    </div>
    <div class="flex-row">
        <div class="flex-1">
            <div>
                <span class="subtitle1">{{ transaction.metadata.brand || transaction.acquirer | qpBrand }}</span>
                <span *ngIf="transaction.test_mode">
                    <mat-icon class="text-yellow s18" i18n-matTooltip matTooltip="Completed using test card"> warning </mat-icon>
                </span>
            </div>

            <div class="flex-col rg8" *ngIf="transaction.facilitator === 'mobilepay_online' && transaction.metadata.bin === null; else cardData">
                <span *ngIf="transaction.metadata.brand === 'visa' || transaction.metadata.brand === 'visa-electron'" i18n>
                    Visa token (VTS)
                </span>
                <span *ngIf="transaction.metadata.brand === 'mastercard' || transaction.metadata.brand === 'mastercard-debet'" i18n>
                    Mastercard token (MS4C)
                </span>
                <span i18n>Last 4 of token: {{ transaction.metadata.last4 }}</span>
            </div>

            <ng-template #cardData>
                <span i18n-matTooltip matTooltip="Completed using test card" [matTooltipDisabled]="!transaction.test_mode"> {{ (transaction.metadata.bin || 'XXXXXX') + 'XXXXXX' + transaction.metadata.last4 | qpFormatCard }} </span>
            </ng-template>
        </div>

        <div class="flex-col flex-1">
            <span class="subtitle1" i18n>3-D Secure</span>
            <div *ngIf="transaction.metadata.is_3d_secure" class="flex-col">
                <div class="flex-row justify-start items-center cg8">
                    <span i18n>Yes</span>
                    <span>{{ get3DSecureStatus() }}</span>
                    <mat-icon color="accent" class="s18 cursor" inline (click)="showInfo = !showInfo"> info </mat-icon>
                </div>
                <div *ngIf="showInfo" class="flex-col">
                    <span class="text-grey" i18n>Y = Full Authentication</span>
                    <span class="text-grey" i18n>A = Successfully Attempted Authentication</span>
                    <a [href]="helpdeskLink" target="_blank" *ngIf="(['Quickpay'] | qpMerchantType)" i18n>Read more</a>
                </div>
            </div>
            <span *ngIf="!transaction.metadata.is_3d_secure" i18n>No</span>
        </div>
    </div>
    <div class="flex-row">
        <div class="flex-1" *ngIf="getCardExpired() !== ''">
            <div><span class="subtitle1" i18n>Expires</span></div>
            <span>{{ getCardExpired() }}</span>
            <mat-icon *ngIf="cardExpired(transaction.metadata.exp_year, transaction.metadata.exp_month)" class="text-darkred s18" i18n-matTooltip matTooltip="Card is expired"> event_busy </mat-icon>
        </div>
        <div class="flex-1">
            <div><span class="subtitle1" i18n>Card issued in</span></div>
            {{ transaction.metadata.country }}
        </div>
    </div>
    <div class="flex-row" *ngIf="transaction.fee">
        <div class="flex-1">
            <div><span class="subtitle1" i18n>Corporate card</span></div>
            <span *ngIf="!transaction.metadata.corporate" i18n>No</span>
            <span *ngIf="transaction.metadata.corporate" i18n>Yes</span>
        </div>
    </div>
    <div class="flex-row" *ngIf="transaction.metadata?.scheme_token">
        <div class="flex-100">
            <p>
                <span class="subtitle1" i18n>Tokenized</span>
                <mat-icon class="s18 text-green">tokenized</mat-icon>
                <br />
                <span i18n>This payment has been processed as a digital token.</span>
            </p>
        </div>
    </div>
    <div class="flex-row">
        <div class="flex-100">
            <qp-transaction-fraud [metadata]="transaction.metadata"></qp-transaction-fraud>
        </div>
    </div>
    <div class="flex-row" *ngIf="disableFraud !== true">
        <qp-transaction-fraud-report [transaction]="transaction" (callBack)="onMakeCallBack()" [permission]="permission" [transactionRepo]="transactionRepo"> </qp-transaction-fraud-report>
    </div>
    <div class="flex-1" *ngIf="transaction.metadata.customer_ip">
        <span class="subtitle1" i18n>Customer IP</span><br />
        {{ transaction.metadata.customer_ip }}
    </div>
    <div class="flex-1" *ngIf="transaction.metadata.customer_country">
        <span class="subtitle1" i18n>Customer country</span><br />
        {{ transaction.metadata.customer_country }}
    </div>
</div>
